<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Layanan Informasi</h3>
            <hr>

            <div class="row p-t-20">
              
              <div class="col-md-12 m-t-10 m-b-10">
                <label>
                  Judul Informasi
                  <small style="color:red;"></small>
                </label>
                <div>
                  <Input v-model="payload.judulinformasi" name="judulinformasi" required type="text" />
                </div>
              </div>
              
            </div>

            <div class="row">

              <div class="col-md-12 m-t-10 m-b-10">
                <label>
                  Keterangan Informasi
                  <small style="color:red;">*</small>
                </label>
                <div>
                  <Input v-model="payload.keteranganinformasi" name="keteranganinformasi" required type="text" />
                </div>
              </div>

            </div>

            <hr class="m-t-40">

          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success" title="Simpan">
              <i class="fa fa-check"></i> Save
            </button> &nbsp;
            <button type="button" @click="goBack" class="btn btn-inverse" title="Cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.judulinformasi;
      return this.$store.state.keteranganinformasi;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidTypeSurat() {
      return (
        this.dropdownTypeSurat.isTouched &&
        this.dropdownTypeSurat.value.length === 0
      );
    }

  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        judulinformasi: "",
        keteranganinformasi: ""
      },

      // dropdownTypeSurat: {
      //   isTouched: false,
      //   tyratList: [],
      //   value: [],
      //   isLoadingTypeSurat: false
      // },

      myHTML: "",
      myFile: [],
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    getAllReference() {
      var query = "";
      this.typeSurat(query);
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/layananinformasi/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("layananInformasi/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("layananInformasi/onAdd");
          // axios.get(`/document_in/new`).then(response => {
          //   // this.payload.agenda = response.data.data.agenda_number;
          //   // this.payload.tanggal = response.data.data.document_date;
          // });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        judulinformasi: data.document_judulinformasi,
        keteranganinformasi: data.document_keteranganinformasi
      };
      this.myHTML = data.description;
    },
    goBack() {
      this.$store.dispatch("layananInformasi/onCancel");
    },
    submit() {
      // if (
      //   this.dropdownTypeSurat.value.length === 0
      // ) {
      //   this.dropdownTypeSurat.isTouched = true;
      //   window.scrollTo(0, 0);
      // }
      // if (this.payload.file) {
      //   this.myFile = this.payload.file.split(",");
      // }
      const payload = {
        layananinformasi: this.payload.layananinformasi,
        keteranganinformasi: this.payload.keteranganinformasi,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (
          success &&
          !this.isInvalidTypeSurat
        ) {
          if (this.editMode) {
            this.$store.dispatch("layananKeterangan/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("layananKeterangan/submitAdd", data);
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },

    // typeSurat(query) {
    //   this.dropdownTypeSurat.isLoadingTypeSurat = true;
    //   axios
    //     .get(`typeSurat/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownTypeSurat.tipesuratList = res.data.items;
    //       this.dropdownTypeSurat.isLoadingTypeSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         store.commit("auth/LOGOUT");
    //       }
    //     });
    // },
    onTouchTypeSurat() {
      this.dropdownTypeSurat.isTouched = true;
    }, 
    onChangeTypeSurat(value) {
      this.dropdownTypeSurat.value = value;
    },
    nomorDokumen(value) {
      this.payload.nomor_dokumen = value.document_number;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

